<template>
  <div class="registration-step-1-block">
    <div class="page-title"><h2>{{ $lang.app.application_check_preschool }}</h2></div>
    <div v-if="!results" class="check-block-container">
      <h4 class="hint-title">{{ $lang.app.application_check_instruction }}</h4>
      <div class="filters-tabs">
        <div class="app-tabs status-tabs">
          <button v-for="formType in formTypes" :key="formType.value"
                  @click.prevent="currentFormType = formType.value"
                  class="app-tab"
                  :class="{'app-tab-active': formType.value === currentFormType }">
            {{ formType.label }}
          </button>
        </div>
      </div>
      <div v-if="currentFormType === 'pin'" class="reg-step-form">
        <Form @submit="submitPinForm" :validation-schema="pinFormSchema">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.child_pin }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="pin" type="text" autocomplete="off"
                     v-maska="'##############'"
                     v-model="pinForm.pin"/>
              <div class="tooltip-target">
                <i class="bi-question-circle"></i>
                <div class="tooltip-content tooltip-content-small">
                  <p>{{ $lang.app.birth_certificate_des }}</p>
                  <p>{{ $lang.app.birth_certificate_example }}</p>
                  <div class="passport-images">
                    <div class="passport-image">
                      <img src="../../assets/images/birth-cert.png" alt="">
                      <span>{{ $lang.app.birth_certificate }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ErrorMessage name="pin" class="recovery-label-error" />
          </div>

          <div class="reg-step-btn">
            <button class="button-transparent" type="button" @click="resetPinForm">{{ $lang.app.reset }}</button>
            <button type="submit">{{ $lang.app.check }}</button>
          </div>
        </Form>
      </div>

      <div v-if="currentFormType === 'birth-certificate'" class="reg-step-form">
        <Form @submit="submitBirthCertificateForm" :validation-schema="birthCertificateFormSchema">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.child_citizenship }}</label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="citizenship"
                     v-model="birthCertificateForm.citizenship">
                <option value="" disabled>{{ $lang.app.select_from }}</option>
                <option v-for="citizenship in citizenships" :key="citizenship.value"
                        :value="citizenship.value">{{ citizenship.label}}</option>
              </Field>
            </div>
            <ErrorMessage name="citizenship" class="recovery-label-error" />
          </div>
          <div class="double-input">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.birth_certificate_seria }}</label>
              <div v-if="birthCertificateForm.citizenship === 'FOREIGN'" class="reg-step-1-select-container">
                <Field name="birthCertificateRange" type="text"
                       v-model="birthCertificateForm.birthCertificateRange"/>
              </div>
              <div v-else class="reg-step-1-select-container">
                <i class="bi-chevron-down"></i>
                <Field as="select" name="birthCertificateRange"
                       v-model="birthCertificateForm.birthCertificateRange">
                  <option value="" disabled>{{ $lang.app.select_from }}</option>
                  <option v-for="birthCertificateRange in birthCertificateRanges" :key="birthCertificateRange"
                          :value="birthCertificateRange">{{ birthCertificateRange }}</option>
                </Field>
              </div>
              <ErrorMessage name="birthCertificateRange" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.birth_certificate_number }}</label>
              <div class="reg-step-1-select-container">
                <Field name="birthCertificateId" type="text"
                       v-maska="'#######'"
                       v-model="birthCertificateForm.birthCertificateId"/>
              </div>
              <ErrorMessage name="birthCertificateId" class="recovery-label-error" />
            </div>
          </div>

          <div class="reg-step-btn">
            <button class="button-transparent" type="button" @click="resetBirthCertificateForm">{{ $lang.app.reset }}</button>
            <button type="submit">{{ $lang.app.check }}</button>
          </div>
        </Form>
      </div>

      <div v-if="currentFormType === 'fio'" class="reg-step-form">
        <Form @submit="submitFioForm" :validation-schema="fioFormSchema">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.surname }} <i>*</i></label>
            <div class="reg-step-1-select-container with-letter-buttons">
              <Field name="lastName" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="fioForm.lastName"/>
              <ExtraKyrgyzLetters @change="fioForm.lastName += $event"/>
            </div>
            <ErrorMessage name="lastName" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.name }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="firstName" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="fioForm.firstName"/>
              <ExtraKyrgyzLetters @change="fioForm.firstName += $event"/>
            </div>
            <ErrorMessage name="firstName" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.middle_name }} </label>
            <div class="reg-step-1-select-container">
              <Field name="middleName" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="fioForm.middleName"/>
              <ExtraKyrgyzLetters @change="fioForm.middleName += $event"/>
            </div>
            <ErrorMessage name="middleName" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.birth_date }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="dateOfBirth" type="date"
                     v-model="fioForm.dateOfBirth"/>
            </div>
            <ErrorMessage name="dateOfBirth" class="recovery-label-error" />
          </div>

          <div class="reg-step-btn">
            <button class="button-transparent" type="button" @click="resetFioForm">{{ $lang.app.reset }}</button>
            <button type="submit">{{ $lang.app.check }}</button>
          </div>
        </Form>
      </div>

      <div class="note-block">
        <span class="note-title">{{ $lang.app.note_title }}</span>
        {{ $lang.app.application_queue_note }}
      </div>
    </div>

    <div v-if="results" class="check-results-block-container">
      <div class="check-results-btn"><a href @click.prevent="results = null">{{ $lang.app.return_back }}</a></div>
      <div class="note-block-wrapper">
        <div class="note-block">
          <span class="note-title">{{ $lang.app.note_title }}</span>
          {{ $lang.app.application_queue_note }}
        </div>
      </div>
      <PreschoolApplicationStatusResult :results="results"/>
    </div>

  </div>
</template>
<script>
import { maska } from 'maska'
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from "yup";
import PreschoolApplicationStatusResult from '../../components/application-status/PreschoolApplicationStatusResult'
import ExtraKyrgyzLetters from "../../components/ExtraKyrgyzLetters";

export default {
  name: 'ApplicationStatus',
  components: {
    Field,
    Form,
    ErrorMessage,
    PreschoolApplicationStatusResult,
    ExtraKyrgyzLetters,
  },
  directives: { maska },
  data() {
    return {
      helper: {
        formaTypes: [
          {
            label: { ru: 'Проверка по ПИН', kg: 'ИЖН аркылуу текшерүү' },
            value: 'pin'
          },
          {
            label: { ru: 'Проверка по свидетельству о рождении', kg: 'Туулгандыгы тууралуу күбөлүк аркылуу текшерүү' },
            value: 'birth-certificate'
          },
          {
            label: { ru: 'Проверка по ФИО', kg: 'Толук аты аркылуу текшерүү' },
            value: 'fio'
          }
        ],
      },
      currentFormType: 'pin',

      birthCertificateRanges: ['KP-X', 'KGZ01'],
      citizenships: [
        {
          label: this.$lang.app.child_citizen_kg,
          value: 'KG'
        },
        {
          label: this.$lang.app.child_citizen_foreign,
          value: 'FOREIGN'
        }
      ],
      pinForm: {
        pin: '',
        recaptchaToken: '',
      },
      pinFormSchema: yup.object({
        pin: yup.string().required()
      }),
      birthCertificateForm: {
        citizenship: null,
        birthCertificateRange: null,
        birthCertificateId: '',
        recaptchaToken: '',
      },
      birthCertificateFormSchema: yup.object({
        citizenship: yup.mixed().required(),
        birthCertificateRange: yup.mixed().required(),
        birthCertificateId: yup.string().required(),
      }),
      fioForm: {
        lastName: '',
        firstName: '',
        middleName: '',
        dateOfBirth: null,
        recaptchaToken: '',
      },
      fioFormSchema: yup.object({
        lastName: yup.string().required(),
        firstName: yup.string().required(),
        dateOfBirth: yup.mixed().required(),
      }),
      results: null,
    }
  },
  computed: {
    formTypes() {
      return this.helper.formaTypes.map(type => {
        return {
          ...type,
          label: this.$lang.$translate(type.label)
        };
      });
    },
  },
  watch: {
    '$lang.currentLanguage'() {
      this.setOrganizationTypesTranslations();
    },
  },
  methods: {
    setOrganizationTypesTranslations() {
      const pinLabel = this.$lang.$translate({ ru: 'Проверка по ПИН', kg: 'ИЖН аркылуу текшерүү' });
      const fioLabel = this.$lang.$translate({ ru: 'Проверка по свидетельству о рождении', kg: 'Туулгандыгы тууралуу күбөлүк аркылуу текшерүү' });
      const birthCertificateLabel = this.$lang.$translate({ ru: 'Проверка по ФИО', kg: 'Толук аты аркылуу текшерүү' });

      this.helper.formaTypes.forEach(type => {
        if (type.value === 'pin') {
          type.label = pinLabel;
        } else if (type.value === 'fio') {
          type.label = fioLabel;
        } else if (type.value === 'birth-certificate') {
          type.label = birthCertificateLabel;
        }
      });
    },
    resetPinForm() {
      this.pinForm.pin = ''
    },
    resetBirthCertificateForm() {
      this.birthCertificateForm.citizenship = null
      this.birthCertificateForm.birthCertificateRange = null
      this.birthCertificateForm.birthCertificateId = ''
    },
    resetFioForm() {
      this.fioForm.lastName = ''
      this.fioForm.firstName = ''
      this.fioForm.middleName = ''
      this.fioForm.dateOfBirth = null
    },
    async submitPinForm(values, actions) {
      // this.$store.commit('showCautionModal', {title: this.$lang.app.application_queue_caution_title})

      this.$store.commit('startLoading')

      await this.$recaptchaLoaded()
      this.pinForm.recaptchaToken = await this.$recaptcha('login')

      this.$axios.post(`/application/check-preschool-status`, this.pinForm).then(({data}) => {
        this.results = data
      }).catch(error => {
        if(error.response.status === 400) {
          actions.setErrors(error.response.data.errors);
        }
      }).finally(() => {
        this.$store.commit('stopLoading');
      })
    },
    async submitBirthCertificateForm(values, actions) {
      this.$store.commit('startLoading')

      await this.$recaptchaLoaded()
      this.birthCertificateForm.recaptchaToken = await this.$recaptcha('login')

      this.$axios.post(`/application/check-preschool-status`, this.birthCertificateForm).then(({data}) => {
        this.results = data
      }).catch(error => {
        if(error.response.status === 400) {
          actions.setErrors(error.response.data.errors);
        }
      }).finally(() => {
        this.$store.commit('stopLoading');
      })
    },
    async submitFioForm(values, actions) {
      this.$store.commit('startLoading')

      await this.$recaptchaLoaded()
      this.fioForm.recaptchaToken = await this.$recaptcha('login')

      this.$axios.post(`/application/check-preschool-status`, this.fioForm).then(({data}) => {
        this.results = data
      }).catch(error => {
        if(error.response.status === 400) {
          actions.setErrors(error.response.data.errors);
        }
      }).finally(() => {
        this.$store.commit('stopLoading');
      })
    },
  }
}
</script>
<style scoped>
@import '../../assets/styles/form.css';
@import '../../assets/styles/title.css';
@import '../../assets/styles/tabs.css';
@import '../../assets/styles/note.css';

.note-block-wrapper {
  border-bottom: 1px solid #d0d0d0;
  padding: 30px 16.6666% 0;
}

.form-or {
  text-align: center;
  margin-bottom: 20px;
}
.check-block-container {
  width: 40%;
  margin: 0 auto;
  padding: 55px 0 45px;
  border-bottom: 1px solid #d0d0d0;
}
.status-tabs .app-tab {
  padding-left: 8px;
  padding-right: 8px;
}
@media screen and (max-width: 1600px) {
  .check-block-container {
    width: 50%;
  }
}
@media screen and (max-width: 1200px) {
  .check-block-container {
    width: 60%;
    padding: 40px 0 50px;
  }
}
@media screen and (max-width: 920px) {
  .status-tabs {
    flex-direction: column;
  }
  .status-tabs .app-tab {
    width: 100% !important;
    border-bottom: 1px solid #d0d0d0 !important;
  }
}
@media screen and (max-width: 767px) {
  .check-block-container {
    width: calc(100% - 30px);
    padding: 30px 0 35px;
  }
  .note-block-wrapper {
    padding: 30px 20px 0;
  }
}
</style>